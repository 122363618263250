import React, {useEffect, useState} from 'react';
import './Days.css'
// @ts-ignore
import InfiniteScroll from 'react-bidirectional-infinite-scroll'
import fb from "firebase";
import Day from "./Day";
import {useCollectionData} from "react-firebase-hooks/firestore";
import "./firebaseApp"
import {CSSTransition, TransitionGroup} from 'react-transition-group';

const add1Day = (d: Date, days: number) => {
    const result = new Date(d);
    result.setDate(result.getDate() + days);
    return result;
};

const daysDiff = (d1: Date, d2: Date) => {
    const diffTime = d1.getTime() - d2.getTime();
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

export interface IDay {
    id: Date
}

// @ts-ignore
function Days({user}) {
    const today = new Date();
    const [days, setDays] = useState<IDay[]>([{id: add1Day(today, -1)}, {id: today}, {id: add1Day(today, 1)}]);
    const [selectedTag, setSelectedTag] = useState<string | null>(null);
    const [clearTags, setClearTags] = useState<boolean>(false);
    const userRef = fb.firestore().collection('users').doc(user?.uid);
    const tagsRef = userRef.collection('tags');
    const [tags, loadingTags] = useCollectionData<{ count: number, name: string }>(tagsRef, {idField: "name"});
    useEffect(() => {
        if (tags && !clearTags) {
            // Clear once on loading
            Promise.all(tags.map(tagDoc => Object.keys(tagDoc).length <= 1 ? tagsRef.doc(tagDoc.name).delete() : undefined))
                .catch((e) => console.error("Failed clearing tags", e))
                .then(() => console.log("Cleared tags"));
            setClearTags(true);
        }
    }, [tags, clearTags, tagsRef]);

    useEffect(() => {
        /*
        TODO: this will fetch all day from now until the max or min tag day found - we need to improve this:
         1. hold smart days array that will handle missing days when scrolling
         2. Day.tsx should only load days which are visible on screen - or about to be visible
         YURDUN?? which one is better?
         */
        if (selectedTag) {
            tagsRef.doc(selectedTag).get().then((snapshot) => {
                const tagDays = Object.keys(snapshot.data() as Object).sort();
                const minDay = new Date(tagDays[0]);
                const maxDay = new Date(tagDays[tagDays.length - 1]);
                setDays(oldDays => {
                    const minDaysDiff = daysDiff(oldDays[0].id, minDay);
                    const pastDays = minDaysDiff > 0 ? Array.from(Array(minDaysDiff).keys()).reverse().map((delta) => ({
                        id: add1Day(oldDays[0].id, (delta + 1) * -1)
                    })) : [];
                    const maxDaysDiff = daysDiff(maxDay, oldDays[oldDays.length - 1].id);
                    const futureDays = maxDaysDiff > 0 ? Array.from(Array(maxDaysDiff).keys()).map((delta) => ({
                        id: add1Day(oldDays[oldDays.length - 1].id, delta + 1)
                    })) : [];
                    return [
                        ...pastDays,
                        ...oldDays,
                        ...futureDays,
                    ]
                })
            });
        }
    }, [selectedTag]);

    const addFutureDays = () => {
        setDays(oldDays => {
            return [...oldDays,
                {id: add1Day(oldDays[oldDays.length - 1].id, 1)},
            ]
        })
    };
    const addPastDays = () => {
        const lastDay = days[0];
        const lastDayId = `day-${lastDay.id.toISOString()}`;
        setTimeout(() => {
            document.getElementById(lastDayId)!.scrollIntoView();
        }, 20);
        setDays(oldDays => {
            return [
                {id: add1Day(oldDays[0].id, -1)},
                ...oldDays
            ]
        })
    };

    useEffect(() => {
        setTimeout(() => {
            document.getElementById("day-today")!.scrollIntoView({
                behavior: "auto",
                block: "center"
            });
        }, 500);
    }, []);

    return (
        <>
            <div className={'tags-header'}>
                {loadingTags && <div/>}
                <TransitionGroup>

                    {(!loadingTags && tags) && tags.filter(tag => Object.keys(tag).length > 1).map(tag => {
                        return <CSSTransition timeout={300} classNames={'taganimation'} key={tag.name}>
                            <div>
                                <button
                                    className={selectedTag === tag.name ? 'button-filter button-filter--selected' : 'button-filter'}
                                    onClick={() => setSelectedTag(t => t === tag.name ? null : tag.name)}>{tag.name}</button>
                            </div>
                        </CSSTransition>
                    })}
                </TransitionGroup>
            </div>
            <div className={'nott'} id={"nott"}>
                <InfiniteScroll
                    onReachBottom={() => addFutureDays()}
                    onReachTop={() => {
                        addPastDays();
                    }}>
                    {days.map(d => <div id={`day-${d.id.toISOString()}`} key={d.id.toISOString()}>
                        <Day user={user} day={d} tag={selectedTag}/>
                    </div>)}
                </InfiniteScroll>
            </div>
        </>
    );
}

export default Days;
