import React, {useEffect, useState} from 'react';
import {IDay} from "./Days";
import "./Day.css"
import DayLine, {IDayLine} from "./DayLine";
import fb from "firebase";
import {useDocumentOnce} from "react-firebase-hooks/firestore";
import {
    Editor,
    EditorState,
    convertFromRaw,
    convertToRaw,
    Modifier,
    RawDraftContentBlock,
    SelectionState,
    ContentState
} from 'draft-js';
import shortid from "shortid";
import {useDebounceFn} from "ahooks";
import Immutable from "immutable";
import firebase from "firebase";
import {getDefaultKeyBinding, KeyBindingUtil} from 'draft-js';
import {sendNots} from "./utils/nots";
import {useAuthState} from "react-firebase-hooks/auth";

const isToday = (d: Date) => {
    const today = new Date();
    return d.getDate() === today.getDate() &&
        d.getMonth() === today.getMonth() &&
        d.getFullYear() === today.getFullYear()
};

const createDayId = (d: Date) => d.toISOString().split('T')[0];

const formatDate = (d: Date) => {
    let prefix = '';
    if (isToday(d)) {
        prefix = 'Today';
    }
    const dminus1 = new Date(d.getTime());
    dminus1.setDate(d.getDate() - 1);
    if (isToday(dminus1)) {
        prefix = 'Tomorrow';
    }
    return `${prefix} ${createDayId(d)}`;
};


function createBlockRenderer() {
    return function myBlockRenderer(contentBlock: any) {
        const type = contentBlock.getType();
        if (type === 'unstyled') {
            return {
                component: DayLine,
                editable: true
            };
        }
    }
}

function convertDayLineToRawBlock(day: IDayLine): RawDraftContentBlock {
    return {
        key: day.key,
        text: day.text || "",
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {
            tags: day.tags || [],
            timestamp: day.timestamp
        }
    };
}

function convertRawBlockToDayLine(raw: RawDraftContentBlock): IDayLine {
    return {
        key: raw.key,
        text: raw.text || "",
        tags: raw.data?.tags || [],
        timestamp: raw.data?.timestamp || new Date()
    }
}

const TAGS_REGEX = /#(?<tags>[^\s#.&;]+)/g;

function getTags(text: string) {
    return text.match(TAGS_REGEX) ?? [];
}

function updateContentStateTags(startKey: string, stopKey: string, content: ContentState) {
    let currentKey: string | undefined;
    do {
        currentKey = !currentKey
            ? startKey
            : content.getKeyAfter(currentKey)
        const block = content.getBlockMap().get(currentKey)

        content = Modifier.mergeBlockData(content,
            SelectionState.createEmpty(block.getKey()),
            Immutable.Map({
                tags: getTags(block.getText()),
                timestamp: new Date()
            }));
    } while (currentKey !== stopKey);
    return content;
}

function Day({day, tag, user}: { day: IDay, tag: string | null, user: fb.User }) {
    const dayId = createDayId(day.id);
    const [lines, setLines] = useState<IDayLine[]>([]);
    const [editorState, setEditorState] = React.useState(() => EditorState.createEmpty(),);
    const userRef = fb.firestore().collection('users').doc(user.uid);
    const dayRef = userRef.collection('days').doc(dayId);
    const tagsRef = userRef.collection('tags');
    const [snapshot, , error] = useDocumentOnce(dayRef);
    const [loaded, setLoaded] = useState(false);

    const sendFeedback = (oldLines: IDayLine[], newLines: IDayLine[]) => {
        for (const newLine of newLines) {
            const oldLine = oldLines.find(l => l.key === newLine.key);
            if (newLine.tags.includes("#feedback")) {
                if (newLine.text !== oldLine?.text) {
                    console.log(`send nots with tag ${newLine.key} and text ${newLine.text}`);
                    sendNots({
                        title: user.email!,
                        text: newLine.text,
                        tag: newLine.key
                    }).then((res) => console.log(`feedback sent`, res)).catch(e => {
                        console.error('Error sending feedback to NOTS');
                        console.log(e);
                    });
                }
            }
        }
    };

    const updateTags = (oldLines: IDayLine[], newLines: IDayLine[]) => {


        try {
            const newTags = newLines.map(line => line.tags).flat();
            const oldTags = oldLines.map(line => line.tags).flat();
            const uniqueNewTags = new Set(newTags);
            const uniqueOldTags = new Set(oldTags);
            const tagsToAdd = Array.from(uniqueNewTags).filter(x => !uniqueOldTags.has(x));
            const tagsToRemove = Array.from(uniqueOldTags).filter(x => !uniqueNewTags.has(x));
            if (tagsToAdd.length > 0) {
                Promise.all(tagsToAdd.map(tag => tagsRef.doc(tag).set({[dayId]: dayRef}, {merge: true}).catch(e => console.log(e))))
                    .then(() => console.log(dayId, 'tags saved'))
            }
            if (tagsToRemove.length > 0) {
                Promise.all(tagsToRemove.map(tag => tagsRef.doc(tag).set({[dayId]: firebase.firestore.FieldValue.delete()}, {merge: true}).catch(e => console.log(e))))
                    .then(() => console.log(dayId, 'tags saved'))
            }
        } catch (error) {
            console.error("Failed to update tags", error);
        }
    };

    const {run: runStateChange} = useDebounceFn(
        (state: EditorState) => {
            const raw = convertToRaw(state.getCurrentContent());
            const newLines = raw.blocks.map(convertRawBlockToDayLine);
            if (loaded && newLines && newLines.length > 0) {
                dayRef.set({lines: newLines}).catch(e => console.log(e)).then(() => console.log(dayId, 'saved'))
            }
            setLines((oldLines) => {
                updateTags(oldLines, newLines);
                sendFeedback(oldLines, newLines);
                return newLines;
            });
        },
        {wait: 800}
    );

    useEffect(() => {
        if (!snapshot) {
            return;
        }
        if (error) {
            console.log(error);
            return
        }

        let initialLines: IDayLine[] = [{key: shortid(), text: "", tags: [], timestamp: new Date()}];
        if (snapshot.exists) {
            initialLines = snapshot.data()!.lines || [];
        }
        setEditorState(EditorState.createWithContent(convertFromRaw({
            entityMap: {},
            blocks: initialLines.map(convertDayLineToRawBlock)
        })));
        setLoaded(true);
        setLines(initialLines);
    }, [error, snapshot]);

    useEffect(() => {
        let currentContent = editorState.getCurrentContent();
        const firstBlock = currentContent.getBlockMap().first();
        const lastBlock = currentContent.getBlockMap().last();
        const firstBlockKey = firstBlock.getKey();
        const lastBlockKey = lastBlock.getKey();
        const lengthOfLastBlock = lastBlock.getLength();
        let newSelection = new SelectionState({
            anchorKey: firstBlockKey,
            anchorOffset: 0,
            focusKey: lastBlockKey,
            focusOffset: lengthOfLastBlock
        });
        const data = Immutable.Map({
            currentTag: tag
        });
        let newContent = Modifier.mergeBlockData(currentContent, newSelection, data);
        let newState = EditorState.push(editorState, newContent, 'change-block-data');
        setEditorState(newState);
    }, [tag]);

    const readOnly = !!tag;
    const hideDay = tag && lines.map(line => line.tags || []).flat().indexOf(tag) < 0;
    return (
        hideDay
            ? <></>
            : <div>
                <div
                    className={`date-header ${tag ? 'date-header--left' : ''} ${isToday(day.id) ? 'date-header--bold' : ``}`}>{formatDate(day.id)}</div>
                <div id={`${isToday(day.id) ? 'day-today' : `day-${dayId}`}`}
                     className={`nott__day ${tag ? "nott_day-dense" : isToday(day.id) ? 'nott_day-today' : ''}`}>
                    <Editor
                        editorState={editorState}
                        onChange={(newEditorState) => {
                            if (readOnly) {
                                return;
                            }
                            const selection = newEditorState.getSelection();
                            const type = newEditorState.getLastChangeType();
                            if (type !== 'undo') {
                                let content = newEditorState.getCurrentContent();
                                if (type === 'split-block') {
                                    const endKey = selection.getEndKey();
                                    const startKey = content.getBlockBefore(endKey)?.getKey() || endKey;
                                    content = updateContentStateTags(startKey, endKey, content);
                                } else if (type === 'insert-fragment') {
                                    // I don't know where the  fragment started... check whole block
                                    const startKey = content.getFirstBlock()?.getKey();
                                    const endKey = content.getLastBlock()?.getKey();
                                    content = updateContentStateTags(startKey, endKey, content);
                                } else {
                                    content = updateContentStateTags(selection.getStartKey(), selection.getEndKey(), newEditorState.getCurrentContent());
                                }
                                newEditorState = EditorState.push(EditorState.undo(newEditorState), content, type)
                                newEditorState = EditorState.acceptSelection(newEditorState, selection);
                            }
                            setEditorState(newEditorState);
                            runStateChange(newEditorState)
                        }}
                        blockRendererFn={createBlockRenderer()}
                        stripPastedStyles={true}
                        // @ts-ignore
                        handleKeyCommand={(command, editorState) => {
                            if (command === 'tab') {
                                let newContentState = Modifier.replaceText(
                                    editorState.getCurrentContent(),
                                    editorState.getSelection(),
                                    "   "
                                );
                                setEditorState(EditorState.push(editorState, newContentState, 'insert-characters'));
                                return 'handled';
                            } else if (command === 'save') {
                                // TODO: save
                            }
                            return 'not-handled';
                        }}
                        keyBindingFn={function myKeyBindingFn(e): string | null {
                            if (e.code === "Tab") {
                                return 'tab';
                            }
                            if (e.code === "KeyS" && KeyBindingUtil.hasCommandModifier(e)) {
                                return 'save';
                            }
                            return getDefaultKeyBinding(e);
                        }}
                    />
                </div>
            </div>
    );
}

export default Day;
