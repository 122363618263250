import fb from "firebase";

const firebaseConfig = {
    apiKey: "AIzaSyDAQyFAA1Mz9RBwpi10WvsPSUCRdz5v7P0",
    authDomain: "nott-b7571.firebaseapp.com",
    projectId: "nott-b7571",
    storageBucket: "nott-b7571.appspot.com",
    messagingSenderId: "879190103476",
    appId: "1:879190103476:web:1eba05ca2f66b7acb4cee1"
};

fb.initializeApp(firebaseConfig);