import React from 'react';
import {EditorBlock, ContentBlock, ContentState} from 'draft-js';

export interface IDayLine {
    text: string,
    tags: string[],
    key: string,
    timestamp: Date
}

function DayLine(props: any) {
    const {
        block,
        contentState
    }: { block: ContentBlock, contentState: ContentState } = props;
    const tags: string[] = block.getData().get("tags") || [];
    const currentTag: string = block.getData().get("currentTag");

    let hide = false;
    if (currentTag) {
        if (block.getText().length === 0) {
            hide = true;
        } else if (tags.length > 0) {
            hide = tags.indexOf(currentTag) < 0;
        } else {
            let key = block.getKey();
            do {
                let prevBlock = contentState.getBlockBefore(key);
                if (!prevBlock) {
                    // Got to the top -> not tags found -> hide!
                    hide = true;
                    break;
                }

                if (prevBlock.getText().length === 0) {
                    // Found empty line -> no tags found -> hide!
                    hide = true;
                    break;
                }

                const prevTags = prevBlock?.getData().get("tags") || [];
                if (prevTags.length > 0) {
                    // Found tags -> dont hide!
                    hide = prevTags.indexOf(currentTag) < 0;
                    break;
                }

                key = prevBlock.getKey();
            } while (true);
        }
    }

    return hide
        ? <></>
        : <EditorBlock {...props} style={{display: "none"}}/>
}

export default DayLine;
