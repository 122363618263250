import React, {useState} from 'react';
import fb from "firebase";
import {useAuthState} from "react-firebase-hooks/auth";
import "./firebaseApp"
import Days from "./Days";
import {useLocalStorageState} from 'ahooks';
import "./App.css";

function App() {
    const [user, loading] = useAuthState(fb.auth());
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [didCloseWelcomeMessage, setDidCloseWelcomeMessage] = useLocalStorageState('did-close-welcome');

    if (loading) {
        return <div></div>
    }

    if (!user) {
        return <div style={{padding: 10}}>
            <input style={{fontSize: 20}} onChange={e => setEmail(e.target.value)} placeholder={"email"}/>
            <br/>
            <input style={{fontSize: 20}} onChange={e => setPassword(e.target.value)} placeholder={"password"}
                   type={"password"}/>
            <div style={{marginTop: 20}}>
                <button style={{fontSize: 20}} onClick={() => {
                    fb.auth().signInWithEmailAndPassword(email, password).catch(e => alert(e.message))
                }
                }>Login
                </button>
                <br/> <br/>
                <button style={{fontSize: 20}}
                        onClick={() => {
                            const answer = window.prompt('Secret?');
                            if (answer !== "banana") {
                                alert("Ask Sharon for the secret!");
                                return;
                            }
                            fb.auth().createUserWithEmailAndPassword(email, password).catch(e => alert(e.message))
                        }}
                >Create a Slips Account
                </button>
            </div>
        </div>
    }

    return (
        <div>
            {!didCloseWelcomeMessage && <>
              <div className={'blur-bg'}/>
              <div className={'welcome-message'}>
                <h1>Slips Zen:</h1>
                <ul>
                  <li>
                    Type simple text just like in a notepad
                  </li>
                  <li>
                    We will split it into dates for you
                  </li>
                  <li>
                    You may #hashtag lines or paragraphs for easy access later
                  </li>
                  <li>
                    To send us feedback, tag a line with #feedback.
                    <br/>
                    Here is an example:
                    <pre>
                              This product sucks! #feedback
                          </pre>
                  </li>
                </ul>

                <button onClick={() => setDidCloseWelcomeMessage('true')}>
                  Start Writing!
                </button>
              </div>
            </>}
            <div className={'user-details'}>
                {user.email}
                <button onClick={() => fb.auth().signOut().catch(console.error)}>logout</button>
            </div>
            <Days user={user}/>
        </div>
    );
}

export default App;
