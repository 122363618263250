export const sendNots = async ({tag, title, text}: { tag: string, text: string, title: string }) => {
    await fetch(`https://api.nots.im/api/v1/notifications?m=5e2ee16a3c0f470022b1f5ef&t=35c9b440231f5f4ac3ab52c24ac0087b4d30ce95`,
        {
            method: 'post',
            body: JSON.stringify({
                title,
                channelId: '60a7578b49e16f00224aa6b4',
                tag,
                text,
            }),
            headers: {'Content-Type': 'application/json'}
        });
};